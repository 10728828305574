import * as React from "react"
import { Link } from "gatsby"
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import SEO from "../Components/Seo/seo";

// markup
const NotFoundPage = () => {
  return (
    <>
    <Header/>
    <SEO title="Buyers Report" />
    <div className="not-found-page"> 
    <p className="property-buyer-report">This property does not currently have a report available. Get in touch with our team to request a property report.</p>
    <div className="btnfof">
    <Link to="/contact" className="btn btn-outline btnproperty">Contact Us</Link> 
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default NotFoundPage
